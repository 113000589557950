.nav-bar{
    grid-area: nav;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: var(--nav-background-color) !important;
    /* height: 75px; */
    margin-left: calc(50% - 50vw);
    width: 100vw;
    z-index: 100;
    margin-bottom: 45px;
    margin-top: var(--nav-margin-top);
}

.nav-bar .nav-bar-items{
    display: flex;
    justify-content: flex-start;
    padding-left: 0px;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--secondary-text-color);
}

.nav-bar .nav-bar-items .nav-bar-item{
    height: 30px;
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.nav-bar .nav-bar-items .nav-bar-item a{
    color: var(--secondary-text-color);
    font-weight: var(--nav-font-weight);
    font-size: 18px;
    text-transform: var(--nav-text-transform);

    text-underline-offset: 5px;
}

.nav-bar .nav-bar-items .nav-bar-item a:hover{
    text-decoration: underline;
    color: var(--nav-hover-color);
}

/* Shrink grid to one column for smaller screens */
@media (max-width: 768px) {
    .nav-bar .nav-bar-items {
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow: auto;
        padding-left: 15px;
    }
}
.fund-description{
    grid-area: fund-description;
    position: relative;
    min-height: 500px;
}

.teal-box{
    background: var(--teal-box-color);
    position: absolute;
    width: 100vw;
    z-index: -1;
    margin-left: calc(50% - 50vw);
    height: 100%;
}

.fund-description .fund-description-container{
    display: grid;
    grid-template-columns: 1fr var(--fund-information-width);
    grid-template-areas: 
        "fund-title fund-information";
    margin-top: var(--fund-description-container-margin-top);
    margin-bottom: var(--fund-description-container-margin-bottom);
    background: var(--fund-description-background-color);  
}

.fund-description .fund-title{
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    grid-area: fund-title;
    padding: var(--fund-description-padding); 
    background: var(--fund-description-background-color); 
}

.fund-description .prime-fund-title{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    grid-column-start: 1;
    grid-column-end: 3;
    padding: var(--fund-description-padding); 
    background: var(--fund-description-background-color); 
    margin-bottom: 50px;
}

.fund-description .fund-title-loading{
    font: 300 28px/36px Avenir, sans-serif;
}

.fund-description .fund-title .fund-ticker{
    font-weight: var(--title-font-weight); 
    font-size: var(--title-font-size); 
    color: var(--title-color); 
    line-height: var(--title-line-height);
    text-transform: var(--title-text-transform);
    font-family: var(--secondary-font-family);
}

.fund-description .fund-title .fund-name{
    font-weight: 900;
    font-size: 28px;
    line-height: 1em;
}

.fund-description .fund-title .fund-objective{
    font-size: var(--body-text-size);
    font-weight: 300;
    line-height: 1.5em;
}

.fund-description .fund-title .why-fund{
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    
}

.fund-description .fund-title .why-fund-heading{
    font-family: var(--secondary-font-family);
    font-weight: 700;
    font-size: var(--title-why-fund-text-size);
    padding-bottom: var(--title-why-fund-bottom-padding);
    line-height: var(--title-line-height);
}

.fund-description .fund-title .why-fund-bullets{
    font-size: var(--body-text-size);
    font-weight: 300;
    line-height: 1.5em;
}

.fund-description .fund-title .fund-name-change{
    font-size: var(--body-text-size);
    font-weight: 300;
    line-height: 1.5em;
}

.fund-description .fund-title ul li { 
    padding-bottom: var(--title-why-fund-bullet-padding);
}

.fund-description .fund-title ul li::marker{
    color: var(--body-bullet-color);
}

.fund-description .title-divider{
    margin-top: 50px;
    margin-bottom:60px;
    border-bottom: 1px #5B6770 solid;
}

.fund-description .fund-information{
    grid-area: fund-information;
    background: #f4f4f4;
    padding: 45px 40px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.fund-description .fund-information .information-title{
    font: 300 22px/30px Avenir,sans-serif;
}

.fund-description .fund-information .document-links{
    font: 900 15px/20px Avenir,sans-serif;
}

.fund-description .fund-information .document-link{
    padding-bottom: 15px;
}

.fund-description .skeleton-ticker{
    margin-bottom: 30px;
}

@media (max-width: 992px) and (min-width: 768px) { 
    .fund-description .fund-description-container {
        grid-template-columns: auto 300px;
        grid-template-areas: 
            "fund-title fund-information";
    }
}

@media (max-width: 768px) {
    .fund-description .fund-description-container {
        grid-template-columns: auto;
        grid-template-areas: 
            "fund-title"
            "fund-information";
    }
}
.fund-details{
    grid-column: span 2;
    width: 100%;
    margin-bottom: 75px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(156,145,156,1) !important;
    -moz-box-shadow: 0px 0px 0px 0px rgba(156,145,156,1) !important;
    box-shadow:  0px 0px 0px 0px rgba(156,145,156,1) !important;
}

.fund-details .fund-details-loading{
    /* height: 350px; */
    display: flex;
    flex-direction: row;
    column-gap: 75px;
}

.fund-details .fund-details-loading .skeleton-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    height: 100%;
}

.fund-details  .details-title{
    font-weight: var(--title-font-weight); 
    font-size: var(--title-font-size); 
    color: var(--title-color); 
    line-height: var(--title-line-height);
    text-transform: var(--title-text-transform);
    font-family: var(--secondary-font-family);
    margin-bottom: 75px;
}

.fund-details .details-data{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fund-details .details-data .details-metric{
    font-family: var(--primary-font-family);
    display: grid;
    grid-template-areas: 
        "metric-value"
        "metric-description"
        "metric-footnote";
    grid-template-rows: 50px 35px 35px;
    line-height: 2.5em;
    row-gap: 10px;
    align-items: center;
    /* padding-right: 75px; */
}

.fund-details .details-data .details-metric .details-metric-value{
    font-size: var(--title-font-size); 
    font-weight: 700;
    grid-area: metric-value;

}

.fund-details .details-data .details-metric .details-metric-description{
    font-size: var(--body-text-size); 
    font-weight: 700;
    grid-area: metric-description;
    padding-top: 25px;
    padding-bottom: 25px;
}

.fund-details .details-data .details-metric .details-metric-footnote{
    font-size: var(--body-text-size); 
    font-weight: 400;
    grid-area: metric-footnote;
}

@media (max-width: 992px) and (min-width: 768px) { 
    .fund-details .details-data{
        flex-direction: column;
        row-gap: 35px;
        align-items: center;
    }

    .fund-details .details-data .details-metric{
        grid-template-rows: 50px 50px 25px;
        line-height: 2.5em;
        padding-right: 0px;
    }

    .fund-details .details-data .details-metric .details-metric-value,
    .fund-details .details-data .details-metric .details-metric-description,
    .fund-details .details-data .details-metric .details-metric-footnote{
        text-align: center;
    }
}

@media (max-width: 768px) {
    .fund-details .details-data{
        flex-direction: column;
        row-gap: 35px;
        align-items: center;
    }

    .fund-details .details-data .details-metric{
        grid-template-rows: 50px 50px 25px;
        line-height: 2.5em;
        padding-right: 0px;
    }

    .fund-details .details-data .details-metric .details-metric-value,
    .fund-details .details-data .details-metric .details-metric-description,
    .fund-details .details-data .details-metric .details-metric-footnote{
        text-align: center;
    }

}
.fund-overview-full-width{
    grid-column: span 2;
}

.fund-overview-half-width{
    grid-column: span var(--default-col-span);
}

@media (max-width: 768px) {
    .fund-overview-half-width{
        grid-column: span 2;
    }
}
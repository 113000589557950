body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root{

  /* App level settings */
  --full-background: transparent;
  --primary-text-color: #050139;
  --secondary-text-color: #FFF;
  --primary-font-family: Avenir, "Avenir LT 55 Roman", sans-serif;
  --secondary-font-family: Avenir, "Avenir LT 55 Roman", sans-serif;
  --first-content: "fund-description";
  --second-content: "nav";
  --third-content: "banner";
  --app-top-margin: 0px;
  --table-header-background-color: #5A6770;
  --table-header-color: #fff;
  --table-background-color: transparent;
  --table-padding: 0px;
  --table-even-rows-background-color: #f4f4f4;
  --table-row-border-bottom-width: 1px;
  --table-left-column-data-font-weight: 300;
  --body-text-size: 16px;
  --body-bullet-color: #000;
  --table-box-shadow: 0px 0px 0px 0px rgba(156,145,156,1);
  --app-padding-bottom: 0px;

  /* Fund Description */
  --teal-box-color: #05a9b1;
  --fund-description-background-color: #fff;
  --fund-information-width: 350px;
  --fund-description-padding: 45px;

  /* NAV */
  --nav-hover-color: #FFF;
  --nav-text-transform: uppercase;
  --nav-font-weight: 500;
  --nav-margin-top: 0px;
  --nav-background-color: #050139;

  /* Fund Overview */
  --default-col-span: 1;
  --fund-description-container-margin-top: 75px;
  --fund-description-container-margin-bottom: 75px;

  /* Buttons */
  --button-border-radius: 0px;
  --button-background-color: #050139;
  --button-color: #fff;

  /* Titles */
  --title-font-weight: 900;
  --title-font-size: 60px;
  --title-color: #050139;
  --title-line-height: 1em;
  --title-text-transform: uppercase;
  --title-why-fund-text-size: 16px;
  --title-why-fund-bottom-padding: 0px;
  --title-why-fund-bullet-padding: 0px;

  /* Banner */
  --banner-image-url: url("banner.png");
}

[data-theme='wtprime'] {
  
  /* App level settings */
  --full-background: #f3f3f3;
  --primary-text-color: #050139;
  --secondary-text-color: #050139;
  --primary-font-family: "Source Sans Pro", sans-serif;
  --secondary-font-family: "ivypresto-display", sans-serif;
  --first-content: "banner";
  --second-content: "nav";
  --third-content: "fund-description";
  --app-top-margin: 75px;
  --table-header-background-color: #050139;
  --table-header-color: #fff;
  --table-background-color: #fff;
  --table-padding: 35px;
  --table-even-rows-background-color: #f4f4f4;
  --table-row-border-bottom-width: 0px;
  --table-left-column-data-font-weight: 700;
  --body-text-size: 28px;
  --body-bullet-color: #60D3F2;
  --table-box-shadow: 0 35px 25px -25px rgba(156,145,156,1);
  --app-padding-bottom: 75px;

  /* Fund Description */
  --teal-box-color: #f4f4f4;  
  --fund-description-background-color: transparent;
  --fund-information-width: "";
  --fund-description-padding: 0px;

  /* NAV */
  --nav-hover-color: #60D3F2;
  --nav-text-transform: capitalize;
  --nav-font-weight: 700;
  --nav-margin-top: 45px;
  --nav-background-color: transparent;

  /* Fund Overview */
  --default-col-span: 2;
  --fund-description-container-margin-top: 0px;
  --fund-description-container-margin-bottom: 0px;

  /* Buttons */
  --button-border-radius: 45px;
  --button-background-color: #60D3F2;
  --button-color: #050139;

  /* Titles */
  --title-font-weight: 900;
  --title-font-size: 60px;
  --title-color: #050139;
  --title-line-height: 1em;
  --title-text-transform: capitalize;
  --title-why-fund-text-size: 60px;
  --title-why-fund-bottom-padding: 35px;
  --title-why-fund-bullet-padding: 25px;

  /* Banner */
  --banner-image-url: url("banner.png");
}
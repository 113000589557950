.fund-details-layout{
  grid-area: layout;
}

.default-layout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 35px;
    row-gap: 35px;
}

.default-layout > div{
  -webkit-box-shadow: var(--table-box-shadow);
  -moz-box-shadow:  var(--table-box-shadow);
  box-shadow:  var(--table-box-shadow);
}

/* Shrink grid to one column for smaller screens */
@media (max-width: 768px) {
    .default-layout {
        grid-template-columns: 1fr;
    }
}

@media
(prefers-reduced-motion: no-preference) {
  .app {
    scroll-behavior: smooth;
    scroll-padding-top: 65px; 
  }
}
.money-market-holdings{
    grid-column: span 2;
    border: 0px;
    background-color: var(--table-background-color);
    padding: 0px var(--table-padding) var(--table-padding) var(--table-padding);
}

.money-market-holdings .center-header{
    text-align: center !important;
}

.money-market-holdings .right-col-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.money-market-holdings .right-col-header .right-col-header-title{
    width: 100%
}

.money-market-holdings .page-item.active .page-link {
    background-color: #5a6770;
    border-color: #5a6770;
    color: #fff;
}

.money-market-holdings .page-item .page-link{
    color: #5B6770;
}

.money-market-holdings .month-select{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 45px;
    gap: 25px;
}

.money-market-holdings .month-select .month-drop-down{
    width: 250px;
}

.money-market-holdings .month-select .month-drop-down:hover{
    cursor: pointer;
}

.money-market-holdings .month-select .month-drop-down:focus{
    outline-width: 0;
    border-bottom: 1px solid #596770;
}

.money-market-holdings .month-select .monthly-agg{
    font-size: 14px;
    display: flex;
}

.money-market-holdings .month-select .monthly-agg .monthly-agg-skeleton{
    width: 250px;
    font-size: 18px;
}

@media (max-width: 768px) {
    .money-market-holdings{
        grid-column: span 2;
    }

    .money-market-holdings .month-select{
        flex-direction: column;
    }

    .money-market-holdings .month-select .month-drop-down, 
    .money-market-holdings .month-select .monthly-agg .monthly-agg-skeleton{
        width: 100%;
    }
    .money-market-holdings .month-select .monthly-agg .monthly-agg-skeleton{
        width: 100px;
    }
}

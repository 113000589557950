.liquid-assets{
    grid-column: span 2;
    border: 0px;
    background-color: var(--table-background-color);
    padding: 0px var(--table-padding) var(--table-padding) var(--table-padding);
}

.liquid-assets .center-header{
    text-align: center !important;
}

.liquid-assets .right-col-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.liquid-assets .right-col-header .right-col-header-title{
    width: 100%
}

.liquid-assets .page-item.active .page-link {
    background-color: #5a6770;
    border-color: #5a6770;
    color: #fff;
}

.liquid-assets .page-item .page-link{
    color: #5B6770;
}

@media (max-width: 768px) {
    .liquid-assets{
        grid-column: span 2;
    }
}

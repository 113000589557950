.performance-table{
    grid-column: span 2;
    border: 0px;
    background-color: var(--table-background-color);
    padding: 0px var(--table-padding) var(--table-padding) var(--table-padding);

}

.performance-table .cumulative, .performance-table .average-annual{
    text-align: center !important;
}

.performance-table .divider{
    border-right: 1px #fff solid;
}

.performance-table .right-col-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.performance-table .right-col-header .right-col-header-title{
    width: 100%
}

.performance-table .title-padding{
    padding-top: 45px;
}

@media (max-width: 768px) {
    .performance-table{
        grid-column: span 2;
    }
}

.fund-banner-container{
    position: relative;
    padding-bottom: 75px;
}

.fund-banner-container .fund-banner{
    grid-area: banner;
    display: grid;
    grid-template-areas: 
    "headline subtitle"
    "headline description"
    "headline prospectus";
    grid-template-columns: 50% 50%;
    grid-template-rows: 200px auto 75px;
    min-height: 400px;
    height: 100%;
    column-gap: 35px;
    row-gap: 15px;
    -webkit-box-shadow: var(--table-box-shadow);
    -moz-box-shadow:  var(--table-box-shadow);
    box-shadow:  var(--table-box-shadow);
}

.fund-banner-container .banner-background{
    background: #FFF;
    position: absolute;
    width: 100vw;
    z-index: -1;
    margin-left: calc(50% - 50vw);
    height: 100%;
}

.fund-banner-container .fund-banner .headline{

    grid-area: headline;
    background-image: var(--banner-image-url);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;
    justify-content: center;
}

.fund-banner-container .fund-banner .headline .headline-disclosure{
    font-size: 14px;
    font-family: var(--primary-font-family) !important;
    color: #FFF;
    line-height: 1.1em;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
}

.fund-banner-container .fund-banner .headline .headline-text{
    background-color: #FFF;
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}

.fund-banner-container .fund-banner .headline .headline-text .headline-text-phrase{
    font-family: var(--primary-font-family) !important;
    color: var(--button-background-color);
    font-size: 24px;
    line-height: 1.2em;
    width: 100%;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.fund-banner-container .fund-banner .headline .headline-text .headline-text-footnote, 
.fund-banner-container .fund-banner .headline .headline-text .headline-text-footnote-asof{
    text-transform: capitalize;
    font-size: 14px;
    font-family: var(--primary-font-family) !important;
    color: var(--secondary-text-color);
    line-height: 1.2em;
}

.fund-banner-container .fund-banner .headline .headline-text .headline-text-footnote-asof{
    text-transform: lowercase;
    font-size: 14px;
}

.fund-banner-container .fund-banner .skeleton-wrapper{
    width: 75%;
    height: 100%;
}

.fund-banner-container .fund-banner .headline .headline-text .headline-text-value{
    color: var( --secondary-text-color);
    font-family: var(--secondary-font-family) !important;
    font-size: 50px;
}

.fund-banner-container .fund-banner .subtitle{
    grid-area: subtitle;
    font-family: var(--secondary-font-family) !important;
    font-size: 48px;
    align-self: flex-end;
    line-height: 1.1em;
}

.fund-banner-container .fund-banner .description{
    grid-area: description;
    font-family: var(--primary-font-family) !important;
    font-size: 24px;
    line-height: 1.2em;
    color: var(--button-background-color);
    padding-right: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.fund-banner-container .fund-banner .prospectus{
    grid-area: prospectus;
}

.fund-banner-container .fund-banner .prospectus a{
    text-decoration: underline;
    color: var(--nav-hover-color);
    font-size: 24px;
}

.fund-banner-container .fund-banner .description .description-text{
    color: var( --secondary-text-color);
}

.fund-banner-container .fund-banner .description .description-fund{
    color: var(--button-background-color);
}

@media (max-width: 768px) {

    .fund-banner-container .fund-banner .subtitle{
        line-height: 1.1em;
    }

    .fund-banner-container .fund-banner{
        grid-template-areas: 
        "headline"
        "subtitle"
        "description"
        "prospectus";
        grid-template-columns: 1fr;
        grid-template-rows: 400px auto auto 90px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .fund-banner-container .fund-banner .headline{
        padding-top: 25px;
        padding-bottom: 15px;

    }
}


.two-column{
    background-color: var(--table-background-color);
    padding: 0px var(--table-padding) var(--table-padding) var(--table-padding);
}

.two-column-table {
    border: 0px;
}

.two-column-table .table-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.two-column-table .table-header .right-col-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.two-column-table .left-column-data{
    font-weight: var(--table-left-column-data-font-weight);
}

.two-column-table .page-item.active .page-link {
    background-color: #5a6770;
    border-color: #5a6770;
    color: #fff;
}

.two-column-table .page-item .page-link{
    color: #5B6770;
}

.app-container{
  position: relative;
  margin-top: var(--app-top-margin);
  padding-bottom: var(--app-padding-bottom);
}

.app {
  font-family: var(--primary-font-family);
  font-size: 13px;
  line-height: 150%;
  height: 100%;
  display: grid;
  flex-direction: column;
  color: var(--primary-text-color);
  grid-template-areas: 
    var(--first-content)
    var(--second-content)
    var(--third-content)
    "layout";
}

.app .chart-title{
  font-family: var(--secondary-font-family);
  font-weight: 300;
  font-size: 28px;
  padding-top: 45px;
  padding-bottom: 35px;
}

.app .tooltip{
  font-family: var(--primary-font-family);
  font-size: 13px;
  font-weight: 300;
  background-color: rgb(244, 244, 244);
  color: #000;
}

.full-background{
  background: var(--full-background);
  position: absolute;
  width: 100vw;
  z-index: -1;
  margin-left: calc(50% - 50vw);
  height: 100%;
}

.app .table thead tr, .app .table tbody tr.table-section-head{
  background-color: var(--table-header-background-color);
  color: var(--table-header-color);
  
}

.app .table tbody tr:nth-child(even){
  background-color: var(--table-even-rows-background-color);
}

.app .table tbody tr{
  border-bottom-width: var(--table-row-border-bottom-width);
}

.app button, .app .button{
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color) !important;
  color: var(--button-color)  !important;
}

.app button:hover{
  background-color: var(--button-background-color);
  filter: brightness(110%);
}

.react-responsive-modal-container button {
  padding: 0px !important;
}

.react-responsive-modal-container button:hover {
  background-color: #FFF !important;
}

.prime-qr-code img{
  height: 150px !important;
}

/* .choose-broker .waitlist-form #joinWaitlist{
  background-color: #60D3F2 !important;
} */

@media
(prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 85px; 
  }
}

/* Shrink grid to one column for smaller screens */
@media (max-width: 768px) {
  html {
    scroll-padding-top: 55px; 
  }
}